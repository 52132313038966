import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

const navQuery = graphql`
  {
    gcms {
      paginas(where: {verberg: false}, stage: PUBLISHED) {
        id
        titel
        slug
      }
    }
  }
`; 

const MainMenu = () => {
  const {
    gcms: {paginas},
  } = useStaticQuery(navQuery);

  return (

    <nav id="menu" className="navbar">
    <div className="container container__header container--center">
      <div className="grid__row grid__row--no-bottom-margin">
        <div className="grid__col grid__col--flex">
          <Link className="logo" to={"/"}>
            <span className="sr-only">Wild Kids</span>
          </Link>
          <div className="menu">
            <ul>
              {paginas.map(({id, ...pagina}) => (
                <li key={id}>
                  <Link key={id} to={`/${pagina.slug}`} activeClassName="highlighted">
                    {pagina.titel}
                  </Link>
                </li>
              ))}
            </ul>

          </div>
        </div>  
      </div>
    </div>  
  </nav>
  )
}

export default MainMenu