import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import '../stylesheet/main.scss'

const PageHelmet = ( {title} ) => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`)
  return (
    <Helmet htmlAttributes={{lang: 'nl'}}>

      <title>{title} | {data.site.siteMetadata.title}</title>

      <meta name="description" content={data.site.siteMetadata.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Noto+Sans:wght@400;700&display=swap" rel="stylesheet"/>

    </Helmet>
  )
}

export default PageHelmet
