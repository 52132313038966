import * as React from 'react'
import footerlogo from '../../assets/img/layout/footerlogo.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container container__header container--center">
        <div className="grid__row grid__row--no-bottom-margin">
          <div className="grid__col grid__col--50">
            <img className="footerlogo" alt="Wild Kids logo" src={footerlogo} />
            <div className="description">
              &quot;Elk kind BuitenGewoon&quot;
            </div>
          </div>
          <div className="grid__col grid__col--50">
            <div className="address">
              <p>
                Wild Kids Thuisbasis<br/>
                Cleyne Raboesdreef 19<br/>
                1262 AC Blaricum
              </p>
              <p>
                <a href="tel:+31643851443">06 43 85 14 43</a><br/>
                <a href="mailto:info@wildkids.nu">info@wildkids.nu</a><br/>
                <a className="social" target="_blank" rel="noreferrer" href="https://www.instagram.com/wildkidseurope/"><i className="fab fa-instagram" aria-hidden="true"></i> instagram</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer