import * as React from 'react'
import MainMenu from '../components/menu'
import HeroImage from '../components/hero'
import Footer from '../containers/footer'
import PageHelmet from '../containers/helmet'

const Layout = ({ pageTitle, pageID, subTitle, pageType, heroImage, showOverlay, children }) => {
  return (
    <div className={`${pageType} page-content`}>
      <PageHelmet title={pageTitle}/>
      <MainMenu />
      <div className={"container container__content container--center " + (heroImage ? '' : 'container__nohero')}>
        {heroImage && (
          <HeroImage page={pageID} url={heroImage} overlay={showOverlay}/>
        )}
        <div className="grid__row homepage-section__container">
          <div className="grid__col">     
            <h1>{pageTitle} {subTitle && (" - ")} {subTitle}</h1>
            {children}
          </div>
        </div>
      </div>  
      <Footer />
    </div>
  )
}

export default Layout