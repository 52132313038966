import * as React from 'react'
import PropTypes from "prop-types"

const HeroImage = ( {url, overlay } ) => {
  return (
    <div className="hero" style={{ backgroundImage:`url(${url})` }}>
      {overlay && (
        <div className="overlay" />
      )}
    </div>  
  )
}

export default HeroImage

HeroImage.propTypes = {
  url: PropTypes.string,
  overlay: PropTypes.bool,
}

HeroImage.defaultProps = {
  url: null,
  overlay: false,
}